<template>
  <div class="roadmap">
    <h1>Roadmap</h1>
    <div class="roadmap-content">
      <!-- <template v-if="typeof roadmapDataRefItems !== 'undefined'">
        <div class="roadmap-content_item left-content">
          <Toggle v-model="selectedRoadmap" :items="['Chapters', 'Research Papers']" @click="updateData"/>
          <TheRoadmapLeftColumn :roadmapObj="content" v-model="roadmapDataRefItems" @update-roadmap-data="updateData"/>
        </div>
      </template> -->
      <div class="roadmap-content_item right-content">
        <h2>Roadmap</h2>
        <div class="info-block">
          <img src="../assets/icons/roadmap-info.svg" alt="info" />
          <p class="hint-text">
            Adjust parameters on the left to get an approximate picture of what
            you need to accomplish.<br />
            Although tentative, this graph may help you plan your work and
            manage time better.
          </p>
        </div>
        <!-- <template v-if="typeof roadmapDataRefItems !== 'undefined'">
          <TheRoadmapStats
              :type="selectedRoadmap"
              :milestones="roadmapDataRefItems.milestones.value"
              :goal="roadmapDataRefItems.goal.value"
              :startDate="roadmapDataRefItems.startDate.value"
              :endDate="roadmapDataRefItems.endDate.value"
          ></TheRoadmapStats>
        </template> -->
      </div>
    </div>
    <!--
    <div class="partner">
      <img :src="require('@/assets/images/course/footer-logo.png')" alt="logo">
    </div>
    -->
  </div>
</template>

<script>
import { getRoadmapInfo, saveRoadmapInfo } from "@/api/roadmap";

import { ref, toRefs, reactive, computed, watch, inject } from "vue";

import TextInputNew from "@/components/UI-Kit/TextInputNew";
import Range from "@/components/UI-Kit/Range";
import Toggle from "@/components/UI-Kit/Toggle";

// import TheRoadmapLeftColumn from "@/components/Roadmap/TheRoadmapLeftColumn"
// import TheRoadmapStats from "@/components/Roadmap/TheRoadmapStats"
import ButtonNew from "@/components/UI-Kit/ButtonNew";

// const roadmapObjects = {
//   'Chapters'
// }

const initialRoadmapSetup = function(props) {
  const roadmapData = ref(undefined);
  const load = computed(() => typeof roadmapData.value === "undefined");
  const roadmapDataRefItems = computed(() =>
    load.value ? undefined : toRefs(roadmapData.value)
  );
  const user = inject("user");
  const socket = inject("socket");

  socket.value.on("updated_roadmap", (data) => {
    let authorId = data.author;
    let roadmapNewData = data.data;
    if (authorId !== user.id) {
      roadmapData.value = reactive(roadmapNewData);
    }
  });

  const initRoadmap = async function() {
    roadmapData.value = undefined;
    roadmapData.value = reactive(await getRoadmapInfo(props.workspaceId));
  };

  const saveRoadmap = async function() {
    await saveRoadmapInfo(props.workspaceId, roadmapData.value);
  };

  const updateData = async function() {
    await saveRoadmap();
  };

  initRoadmap();

  watch(
    () => props.workspaceId,
    () => {
      initRoadmap();
    }
  );

  return {
    roadmapData,
    roadmapDataRefItems,
    load,
    saveRoadmap,
    updateData,
  };
};

export default {
  name: "Roadmap",
  props: {
    workspaceId: {
      type: String,
      required: true,
    },
  },
  components: {
    ButtonNew,
    TextInputNew,
    // TheRoadmapLeftColumn,
    // TheRoadmapStats,
    Range,
    Toggle,
  },
  setup(props) {
    const {
      load,
      roadmapData,
      roadmapDataRefItems,
      saveRoadmap,
      updateData,
    } = initialRoadmapSetup(props);

    const dateStart = "2020-05-07";
    const dateEnd = "2022-11-07";

    const selectedRoadmap = computed({
      get: () => {
        if (load.value || roadmapDataRefItems.value.type.value === "chapters") {
          return "Chapters";
        } else if (roadmapDataRefItems.value.type.value === "researchpapers") {
          return "Research Papers";
        }
      },
      set: (value) => {
        const newValue = value
          .toLowerCase()
          .split(" ")
          .join("");
        if (newValue === "chapters") {
          roadmapDataRefItems.value.milestones.value = 3;
          roadmapDataRefItems.value.goal.value = 80000;
        } else if (newValue === "researchpapers") {
          roadmapDataRefItems.value.milestones.value = 3;
          roadmapDataRefItems.value.goal.value = 4000;
        }
        roadmapDataRefItems.value.type.value = newValue;
      },
    });

    const content = computed(
      () =>
        ({
          Chapters: {
            texts: [
              "How many chapters do you need to write?",
              "What is your thesis expected length?",
            ],
            range: [40000, 150000],
          },
          "Research Papers": {
            texts: [
              "How many research papers do you need to write?",
              "What is an approximate length of each paper?",
            ],
            range: [2000, 10000],
          },
        }[selectedRoadmap.value])
    );

    return {
      selectedRoadmap,
      content,
      dateStart,
      dateEnd,
      load, // Можно приостановить рендеринг условной отрисовкой, (директива v-if)
      // пока данные не загружены (чтобы избежать ошибок)

      roadmapData, // Reactive { item, item, item }
      // Использовать для работы с объектом и его свойствами,
      //  если передать просто одно значение - потеряет реактивность

      roadmapDataRefItems,
      saveRoadmap,
      updateData, // { Ref, Ref, Ref } Использовать для работы со свойствами по отдельности,
      // они останутся реактивными
    };
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/styles/color-scheme.scss";
/*
  Файлы уже подключены на страницу,
  можно посмотреть в assets/styles/
  Типографика - typograph.scss
  Цвета - color-scheme.scss
  https://www.figma.com/file/t5LMlNMztQtoAzTlw4gpU7/Untitled?node-id=0%3A1
 */

.roadmap {
  min-height: 100vh;
  width: 100%;
  background-color: $pxp-gray-added-color;
  padding: 25px 0;
}

h1 {
  text-align: left;
  padding: 0 25px;
  margin-bottom: 20px;
}

h2 {
  margin-bottom: 16px;
}

.roadmap-content {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 16px;
  padding: 0 25px;
  box-sizing: border-box;
}

.roadmap-content_item {
  background-color: #fff;
  width: 100%;
  min-height: 300px;
  text-align: left;
  padding: 16px 22px;
  box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  box-sizing: border-box;
}

.toggle {
  width: 225px;
  margin: 0 auto 16px;
}

.info-block {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.info-block img {
  margin-right: 18px;
}
</style>
